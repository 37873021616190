<template>
	<div id="">
		<div id="payheader">
			<el-button type="button" icon="el-icon-arrow-left" @click='$router.back()'></el-button>
			<p style="font-weight: bold;">支付</p>
			<el-button type="button"></el-button>
		</div>
		<div id="numbox">
			<p>实付金额</p>
			<span id="pic">
				￥{{shopobj.Pic}}
			</span>
		</div>
		<div id="paytypr">
			<p>选择支付方式</p>
			<div id="paybox" v-if="flage">
				<el-radio style="padding-top: 5px;border: none;" v-model="shopobj.radio" label="1" border>
					<img style="width: 90px; height: 30px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/zfb.png">
				</el-radio>
			</div>
			<!-- <div id="paybox">
				<el-radio style="padding-top:2px;border: none;" v-model="shopobj.radio" label="2" border>
					<img style="width: 95px; height: 35px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wpay.png">
				</el-radio>
			</div> -->
		</div>
		<div id="paybtn">
			<button v-if="shopobj.radio=='1'" style="background-color: #1677FF;" type="button"
				@click="payfun">支付宝支付</button>
			<button v-if="shopobj.radio=='2'" style="background-color: #33CC00;" type="button"
				@click="payfun">微信支付</button>
		</div>
	</div>
</template>

<script>
	const axi = require('../../assets/axi.js')
	export default {
		data() {
			return {
				shopobj: {},
				flage: true
			}
		},
		created() {
			console.log(this.$store.state.Phonepaydata)
			this.shopobj = this.$store.state.Phonepaydata
			var browser = {
				versions: function() {
					var u = navigator.userAgent,
						app = navigator.appVersion;
					return { //移动终端浏览器版本信息
						trident: u.indexOf('Trident') > -1, //IE内核
						presto: u.indexOf('Presto') > -1, //opera内核
						webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
						iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, //是否iPad
						webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
					};
				}(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
			if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
				var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					this.flage = false
				}
			} else {
				var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					this.flage = false
				}
			}
		},
		methods: {
			payfun() {
				var browser = {
					versions: function() {
						var u = navigator.userAgent,
							app = navigator.appVersion;
						return { //移动终端浏览器版本信息
							trident: u.indexOf('Trident') > -1, //IE内核
							presto: u.indexOf('Presto') > -1, //opera内核
							webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
							gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
							mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
							ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
							android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -
								1, //android终端或uc浏览器
							iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
							iPad: u.indexOf('iPad') > -1, //是否iPad
							webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
						};
					}(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase()
				}
				if (this.shopobj.radio == '2') {
					if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
						var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
						if (ua.match(/MicroMessenger/i) == "micromessenger") {
							// alert('wx')
							let data = {
								Key: this.shopobj.Key,
								UserName: this.shopobj.UserName, //账号名称
								UserKey: this.shopobj.UserKey, //用户Key
								Address: this.shopobj.Address, //地址
								Remarks: this.shopobj.Remarks, //备注
								radio: this.shopobj.radio, //支付方式
								radios: this.shopobj.radios, //发票选择
								rise: this.shopobj.rise, // 抬头
								dutyparagraph: this.shopobj.dutyparagraph, //税号
								Email: this.shopobj.Email, //邮箱
								Invitationcode: this.shopobj.Invitationcode,
								InvitationCodes: this.shopobj.InvitationCodes,
								Unit:this.shopobj.Unit,
								Businessaddress:this.shopobj.Businessaddress,
								Accountnumberofopeningbank:this.shopobj.Accountnumberofopeningbank,
								bankaddress:this.shopobj.bankaddress,
								enterprisephone:this.shopobj.enterprisephone
							}
							var newString = JSON.stringify(data)
							window.location.href =
								'https://open.weixin.qq.com/connect/oauth2/authorize?Appid=wxc5a84247c3c9948f&redirect_uri=http%3A%2F%2Fwww.rhdgj.com%2F%23%2Finformation&response_type=code&scope=snsapi_base&state=' +
								newString + '#wechat_redirect'

						}
						if (browser.versions.ios || browser.versions.android) {
							//是否在IOS浏览器打开
							// alert('ios')
							this.webpay()
							// let ip = returnCitySN['cip']
							// window.location.href =
							// 	"weixin://wap/pay?appid%3Dwx2421b1c4370ec43b%26noncestr%3D3e84679af4efab5f32ee9ea01b2ec290%26package%3DWAP%26prepayid%3Dwx20160504154919fdacd7bc0d0127918780%26timestamp%3D1462348159%26sign%3DC40DC4BB970049D6830BA567189B463B"
						}
					} else {
						this.webpay()
					}
				} else {
					let Is_Invoice = false
					let Header = ''
					let TaxNumber = ''
					let Email = ''
					let InvoiceType = ''
					let UnitAddress = ''
					let InvoicePhone = ''
					let InvoiceBank = ''
					let BankAccount = ''
					if (this.shopobj.radios == '1') {
						Is_Invoice = true
						Header = this.shopobj.rise
						TaxNumber = this.shopobj.dutyparagraph
						Email = this.shopobj.Email
						InvoiceType = this.shopobj.Unit
						if (this.shopobj.Unit == '2') {
							UnitAddress = this.shopobj.Businessaddress
							InvoicePhone = this.shopobj.enterprisephone
							InvoiceBank = this.shopobj.bankaddress
							BankAccount = this.shopobj.Accountnumberofopeningbank
						}
					}
					let data = {
						Key: this.shopobj.Key,
						UserName: this.shopobj.UserName,
						UserKey: this.shopobj.UserKey,
						Address: this.shopobj.Address,
						Remarks: this.shopobj.Remarks,
						DataTradId:this.shopobj.DataTradId,
						InvitationCode: this.shopobj.Invitationcode,
						UserInvitationCode: this.shopobj.InvitationCodes,
						Is_Invoice: Is_Invoice,
						Header: Header,
						TaxNumber: TaxNumber,
						Email: Email,
						InvoiceType:InvoiceType,
						UnitAddress:UnitAddress,
						InvoicePhone:InvoicePhone,
						InvoiceBank:InvoiceBank,
						BankAccount:BankAccount
					}
					console.log(data)
					axi.post('Pay/AlipayPhone', data).then((res) => {
						console.log(res)
						console.log('手机')
						if (res.data.Code == 1000) {
							let from = res.data.Dto.split('<script>')[0]
							console.log(from)
							const div = document.createElement('div');
							div.innerHTML = from;
							document.body.appendChild(div);
							document.forms[0].submit();
							document.body.removeChild(div)
						} else {
							this.$message.error(res.data.Msg)
						}
					})
				}
			},
			// H5支付
			webpay() {
				let ip = returnCitySN['cip']
				console.log(ip)
				// let Is_Invoice = false
				// let Header = ''
				// let TaxNumber = ''
				// let Email = ''
				// if (this.shopobj.radios == '1') {
				// 	Is_Invoice = true
				// 	Header = this.shopobj.rise
				// 	TaxNumber = this.shopobj.dutyparagraph
				// 	Email = this.shopobj.Email
				// }
				let Is_Invoice = false
				let Header = ''
				let TaxNumber = ''
				let Email = ''
				let InvoiceType = ''
				let UnitAddress = ''
				let InvoicePhone = ''
				let InvoiceBank = ''
				let BankAccount = ''
				if (this.shopobj.radios == '1') {
					Is_Invoice = true
					Header = this.shopobj.rise
					TaxNumber = this.shopobj.dutyparagraph
					Email = this.shopobj.Email
					InvoiceType = this.shopobj.Unit
					if (this.shopobj.Unit == '2') {
						UnitAddress = this.shopobj.Businessaddress
						InvoicePhone = this.shopobj.enterprisephone
						InvoiceBank = this.shopobj.bankaddress
						BankAccount = this.shopobj.Accountnumberofopeningbank
					}
				}
				let data = {
					Key: this.shopobj.Key,
					UserName: this.shopobj.UserName,
					UserKey: this.shopobj.UserKey,
					Address: this.shopobj.Address,
					Remarks: this.shopobj.Remarks,
					DataTradId:this.shopobj.DataTradId,
					Spbill_create_ip: ip,
					Is_Invoice: Is_Invoice,
					Header: Header,
					TaxNumber: TaxNumber,
					Email: Email,
					InvitationCode: this.shopobj.Invitationcode,
					UserInvitationCode: this.shopobj.InvitationCodes,
					InvoiceType:InvoiceType,
					UnitAddress:UnitAddress,
					InvoicePhone:InvoicePhone,
					InvoiceBank:InvoiceBank,
					BankAccount:BankAccount
				}
				console.log(data)
				axi.post('Pay/WeChatPayMWEBAPI', data).then((res) => {
					// console.log(res)
					if (res.data.Code == 1000) {
						this.$store.commit('prepayidfun', res.data.Dto.Prepay_id)
						// let Url=res.data.Dto.Url.split('https://')[1]
						// console.log(Url)
						res.data.Dto.Url = res.data.Dto.Url +
							'&redirect_url=http%3A%2F%2Fwww.rhdgj.com%2F%23%2FSuccess'
						// console.log(res.data.Dto.Url)
						window.location.href = res.data.Dto.Url
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
		},
	}
</script>

<style scoped="scoped" lang="less">
	#payheader {
		width: 100%;
		height: 50px;
		// background-color: #FFF000;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #e5e4e4;

		button {
			background-color: #FFFFFF;
			border: none;
			height: 100%;
		}

		p {
			height: 100%;
			line-height: 50px;
			margin: 0;
		}
	}

	#numbox {
		width: 100%;
		height: 250px;
		// background-color: #F06431;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;

		p {
			width: 100%;
			text-align: center;
		}

		#pic {
			font-size: 40px;
			font-weight: bold;
		}
	}

	#paytypr {
		width: 90%;
		margin-left: 5%;

		p {
			font-weight: bold;
		}

		#paybox {
			margin-top: 30px;
		}
	}

	#paybtn {
		width: 100%;
		position: fixed;
		bottom: 10px;

		button {
			width: 80%;
			margin-left: 10%;
			height: 40px;
			border-radius: 10px;
			color: #FFFFFF;

		}
	}
</style>
